<template lang="pug">
portal(to="root")
  om-modal(
    name="master-contact-us"
    modalClasses="master-contact-us-modal"
    :width="600"
    color="light"
    @opened="opened"
  )
    template(slot="modal-header")
      .row
        .col
          .text-center.font-size-1--75.master-contact-us-modal-title {{ $t('contactUsModal.title') }}
          .text-center.font-size-1.mt-3.master-contact-us-modal-lead {{ $t('contactUsModal.lead') }}
      .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('master-contact-us')")
        close-icon(:width="12" :height="12" color="#AAB1C1")
    template(slot="modal-body")
      .row
        .col
          input.form-control.form-control-md.mb-1(
            type="text"
            :placeholder="$t('firstName')"
            v-model="firstName"
            :class="{ 'is-invalid': $v.firstName.$error }"
          )
        .col
          input.form-control.form-control-md.mb-1.row.justify-content-center.mlu(
            type="text"
            :placeholder="$t('lastName')"
            v-model="lastName"
            :class="{ 'is-invalid': $v.lastName.$error }"
          )
      .row
        .col
          input.form-control.form-control-md.mb-1(
            type="email"
            :placeholder="$t('emailAddress')"
            v-model="email"
            :class="{ 'is-invalid': $v.email.$error }"
          )
      .row
        .col
          input.form-control.form-control-md.mb-1(
            type="text"
            ref="phoneInput"
            v-model="phoneNumber"
            :class="{ 'is-invalid': $v.phoneNumber.$error }"
          )
      .row(style="margin-top: -10px")
        .col
          input.form-control.form-control-md.mb-1(
            type="text"
            :placeholder="$t('websiteUrl')"
            v-model="websiteUrl"
            :class="{ 'is-invalid': $v.websiteUrl.$error }"
          )
      .row
        .col
          input.form-control.form-control-md.mb-1(
            type="text"
            :placeholder="$t('monthlyPageViews')"
            v-model.number="monthlyPageViews"
            :class="{ 'is-invalid': $v.monthlyPageViews.$error }"
          )
    template(slot="modal-footer")
      .d-flex.flex-column.align-items-center
        om-button(primary @click="send") {{ $t('contactUsModal.btn') }}
</template>

<script>
  import { required, email, integer } from 'vuelidate/lib/validators';
  import { validateDomain } from '@/util';
  import MASTER_CONTACT_US from '@/graphql/MasterContactUs.gql';
  import * as intlTelInput from 'intl-tel-input';
  import 'intl-tel-input/build/js/utils';
  import 'intl-tel-input/build/css/intlTelInput.css';
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        iti: null,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        websiteUrl: '',
        monthlyPageViews: '',
      };
    },

    validations: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        isCoolEmail(v) {
          return email(v.toLowerCase());
        },
        required,
      },
      phoneNumber: {
        isPhoneNumber() {
          return this.iti.isValidNumber();
        },
      },
      websiteUrl: {
        required,
        isUrl(v) {
          return validateDomain(v);
        },
      },
      monthlyPageViews: {
        required,
        integer,
      },
    },
    computed: {
      getDefaultCountry() {
        return this.$i18n.locale === 'hu' ? 'hu' : 'us';
      },
    },

    methods: {
      ...mapGetters(['getLoginFirstName', 'getLoginLastName', 'getAccountEmail']),
      async send() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }

        const {
          data: { sendMasterContactUs },
        } = await this.$apollo.mutate({
          mutation: MASTER_CONTACT_US,
          variables: {
            input: {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email.toLowerCase(),
              phoneNumber: this.iti.getNumber(),
              websiteUrl: this.websiteUrl,
              monthlyPageViews: parseFloat(this.monthlyPageViews),
            },
          },
        });

        if (sendMasterContactUs) {
          this.$notify({
            type: 'success',
            title: this.$t('notifications.sendSuccess'),
          });
          this.$modal.hide('master-contact-us');
        } else {
          this.$notify({
            type: 'error',
            title: this.$t('notifications.sendError'),
          });
        }
      },
      opened() {
        this.firstName = this.getLoginFirstName();
        this.lastName = this.getLoginLastName();
        this.email = this.getAccountEmail();
        this.iti = intlTelInput(this.$refs.phoneInput, { customContainer: 'mb-3' });
        this.iti.setCountry(this.getDefaultCountry);
      },
    },
  };
</script>
<style lang="sass">
  .master-contact-us-modal
    &-title
      color: #D5660C

    &-lead
      color: #5E5C5C

    .brand-modal
      min-height: 100%

      .brand-modal-header
        background: url('~@/assets/admin/img/contact_us_modal_bg.jpg') no-repeat center center / cover
        padding: 2.5rem 3.125rem 1.5rem !important

      .brand-modal-footer
        padding: 0 !important
        margin: 0 !important
        padding-bottom: 1.5625rem !important

      .intl-tel-input
        display: block
        margin-bottom: 3rem
        .country-list
          .divider
            width: auto
            height: auto
            background: initial

      .mlu
        margin-left: unset
</style>
